import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useLocation } from 'react-router';
import qs from 'qs';
import _isNull from 'lodash/isNull';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import ReactCodeInput from 'react-verification-code-input';
import SubmitButton from '../common/SubmitButton';
import PushSnackbarMessage from '../../helpers/PushSnackbarMessage';
import AxiosHeaders from '../../helpers/AxiosHeaders';
import linkWithLang from '../../helpers/LinkWithLanguage';
import getCurrentLanguage from '../../helpers/GetCurrentLanguage';

const styles = theme => ({
  root: {
    minWidth: 275,
    maxWidth: 500,
    padding: theme.spacing(2),
    margin: 'auto',
    textAlign: 'left',
    marginTop: theme.spacing(4),
  },
  des: {
    marginTop: theme.spacing(2),
    padding: '0rem 1rem',
    lineHeight: '1.5rem',
  },
  input: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    margin: theme.spacing(2),
    fontSize: '2rem',
  },
  email: { marginLeft: theme.spacing(1) },
  tryAgain: {
    '&:hover': { cursor: 'pointer' },
  },
  containerGrid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  callAction: {
    display: 'flex',
    alignItems: 'baseline',
    float: 'right',
    '&>a': { marginRight: theme.spacing(1) },
  },
  reactCodeInput: {
    margin: 'auto',
  },
  dBlock: {
    display: 'block',
  },
});

const VerifyForgotPasswordCode = (props) => {
  const isMobile = window.innerWidth <= 768;
  const {
    classes, enqueueSnackbar, setIsLoading,
  } = props;
  const [confirmCode, setConfirmCode] = useState(null);
  const location = useLocation();
  const { t } = useTranslation();

  const lang = getCurrentLanguage();

  const enableSubmit = (
    !_isNull(confirmCode) && confirmCode.length === 4
  );
  const {
    confirmation_token: confirmationToken,
  } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const handleVerifyCode = () => {
    if (enableSubmit) {
      window.location.href = linkWithLang(`${location.pathname}?confirmation_token=${confirmationToken}&confirm_code=${confirmCode}`);
    } else {
      PushSnackbarMessage(enqueueSnackbar, t('The code must include 4 numbers'), 'error');
    }
  };
  const handleResendVerifyEmail = () => {
    if (!_isNull(confirmationToken)) {
      const submitData = Object.assign({});
      submitData.confirmation_token = confirmationToken;

      setIsLoading(true);
      axios({
        url: '/doctor/resend_verification',
        method: 'PUT',
        headers: AxiosHeaders,
        params: submitData,
      }).then(({ status, data }) => {
        if (lang === 'en') {
          PushSnackbarMessage(enqueueSnackbar, t('Email has been resent.'), 'success');
        } else {
          PushSnackbarMessage(enqueueSnackbar, 'メールが再送されました。', 'success');
        }
        setIsLoading(false);
        window.location.href = linkWithLang(`${location.pathname}?confirmation_token=${confirmationToken}`);
      }).catch(() => {
        PushSnackbarMessage(enqueueSnackbar, t('Unable to resend email.'), 'error');
        setIsLoading(false);
      });
    }
  };

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography variant="h5" component="h2">
          {t('Verify your identity')}
        </Typography>
        <Typography variant="body2" className={classes.des}>
          {
            !isMobile ? (
              <p>
                <span className={classes.dBlock}>登録いただいたメールに確認コードを送信いたしま</span>
                <span className={classes.dBlock}>したので、そちらのコードを下記にご入力ください。</span>
                <span className={classes.dBlock}>メールが届いていない場合は、迷惑メールフォルダを</span>
                <span className={classes.dBlock}>確認いただき、それでも見当たらない場合は再送信を</span>
                <span className={classes.dBlock}>リクエストしてください。</span>
                <span className={classes.dBlock}>
                  再送信をリクエストする場合はこちらの
                  <Link className={classes.tryAgain} onClick={handleResendVerifyEmail}>{t('try again')}</Link>
                  を
                </span>
                <span className={classes.dBlock}>クリックしてください。</span>
              </p>
            ) : (
              <p>
                <span className={classes.dBlock}>登録いただいたメールに確認コードを</span>
                <span className={classes.dBlock}>送信いたしましたので、そちらの</span>
                <span className={classes.dBlock}>コードを下記にご入力ください。</span>
                <span className={classes.dBlock}>メールが届いていない場合は、</span>
                <span className={classes.dBlock}>迷惑メールフォルダを確認いただき、</span>
                <span className={classes.dBlock}>それでも見当たらない場合は</span>
                <span className={classes.dBlock}>再送信をリクエストしてください。</span>
                <span className={classes.dBlock}>再送信をリクエストする場合は</span>
                <span className={classes.dBlock}>
                  こちらの
                  <Link className={classes.tryAgain} onClick={handleResendVerifyEmail}>{t('try again')}</Link>
                  を
                </span>
                <span className={classes.dBlock}>クリックしてください。</span>
              </p>
            )
          }
        </Typography>
      </CardContent>
      <Grid container spacing={3} className={classes.containerGrid}>
        <Grid item xs={12}>
          <ReactCodeInput
            fields={4}
            autoFocus
            className={classes.reactCodeInput}
            onChange={(vals) => {
              setConfirmCode(vals);
            }}
          />
        </Grid>
      </Grid>
      <div className={classes.callAction}>
        <SubmitButton text={t('Next')} onClick={handleVerifyCode} isValid={enableSubmit} />
      </div>
    </Card>
  );
};

VerifyForgotPasswordCode.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  setIsLoading: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(VerifyForgotPasswordCode);
