import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import LOGO_DR from '../../../assets/images/logo.png';

const styles = {
  wrapper: {
    padding: '12px 60px',
    background: 'linear-gradient(0deg, rgba(245, 250, 255, 0.30) -3.22%, #EDF6FF 78%)',
    '@media (max-width: 768px)': {
      padding: '12px 16px',
    },
    '@media (max-width: 1200px)': {
      padding: '12px 24px',
    },
  },
  container: {
    maxWidth: '1128px',
    width: '100%',
    margin: '0 auto',
    padding: '24px 0',
    '@media (max-width: 768px)': {
      padding: '24px 0px 12px 0px',
    },
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  logo_container: {
    height: '40px',
    width: '154px',
    '&> img': {
      maxWidth: '100%',
      height: 'auto',
      objectFit: 'cover',
    },
    '@media (max-width: 768px)': {
      marginBottom: '24px',
    },
  },
  gruop_links: {
    display: 'flex',
    minWidth: 415,
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 'auto',
    },
  },
  link_item: {
    flex: 1,
    marginRight: 12,
    display: 'grid',
    placeItems: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '21px',
    color: '#466888',
    '@media (max-width: 768px)': {
      marginRight: '0',
      marginBottom: '24px',
    },
  },
  mr_0: {
    marginRight: 0,
    display: 'flex',
    '&> img': {
      marginRight: 4,
    },
  },
};

function FooterIntroduction({ classes }) {
  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.main}>
          <div className={classes.logo_container}>
            <img src={LOGO_DR} alt="LOGO_DR" />
          </div>
          <div className={classes.gruop_links}>
            <a href="/about" className={classes.link_item}>Dr.Benriとは</a>
            <a href="mailto:contact@drbenri.com" className={classes.link_item}>お問い合わせ</a>
            {/*<a href="/contact-us" className={classes.link_item}>Contact us</a>*/}
            {/*<a href="#" className={`${classes.link_item} ${classes.mr_0}`}>*/}
            {/*  <img src={LANGUAGE_ICON} alt="LANGUAGE_ICON" />*/}
            {/*  English*/}
            {/*</a>*/}
          </div>
        </div>
      </div>
    </div>
  );
}

FooterIntroduction.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(FooterIntroduction);
