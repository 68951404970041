import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { withSnackbar } from 'notistack';
import _isNull from 'lodash/isNull';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoctorPlatformTheme from '../components/common/DoctorPlatformTheme';
import { USER_ACCOUNT_BG, DOCTOR_PLATFORM_LOGO } from '../shared/Constants';
import DoctorRegistrationAccount from '../components/sign-up/DoctorRegistrationAccount';
import VerifyRegistration from '../components/sign-up/VerifyRegistration';
import DoctorGeneralProfile from '../components/sign-up/DoctorGeneralProfile';
import DoctorWorkExperience from '../components/sign-up/DoctorWorkExperience';
import DoctorPassportUpload from '../components/sign-up/DoctorPassportUpload';
import Footer from '../components/common/Footer';
import GetCurrentLanguage from '../helpers/GetCurrentLanguage';
import useResize from '../hooks/useResize';

const lang = GetCurrentLanguage();

const styles = theme => ({
  main: {
    height: 'calc(100vh - 42px)',
    width: '100%',
    background: 'linear-gradient(0deg, rgba(245, 250, 255, 0.30) -3.22%, #EDF6FF 78%)',
    '@media (max-width: 768px)': {
      height: '100%',
    },
  },
  container: {
    display: 'flex',
    height: '100%',
    paddingBottom: theme.spacing(6),
  },
  logo_container: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '0%',
    marginBottom: '60px',
    '@media (max-width:360px)': {
      marginBottom: '4px !important',
    },
    '@media (max-width:500px)': {
      marginBottom: '20px',
    },
  },
  main_content: {
    margin: 'auto',
  },
  logo: { },
  textContent: {
    textAlign: 'center',
    '&>p': {
      marginTop: '12px',
      paddingRight: lang === 'jp' ? theme.spacing(3) : '0',
      fontWeight: '500',
      userSelect: 'none',
    },
    '@media (max-width:768px)': {
      '&>p': {
        paddingRight: '0',
        fontSize: '14px !important',
        marginBottom: '12px',
      },
    },
    '@media (max-width:320px)': {
      display: 'none',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  center: {
    display: 'grid',
    placeItems: 'center',
  },
  sign_up_text: {
    '&>p': {
      color: 'rgba(0, 0, 0, 0.7)',
      fontSize: '22px',
      fontWeight: '500',
      userSelect: 'none',
    },
    '@media only screen and (device-width: 1024px)': {
      '&>p': {
        fontSize: '18px !important',
      },
    },
  },
  sign_up_text_bottom: {
    '@media (min-width:768px)': {
      marginTop: '0 !important',
      marginBottom: '64px',
    },
  },
});

const stepAttributes = (registrationStep, enqueueSnackbar, setIsLoading) => {
  const attributes = {
    stepComponent: <DoctorRegistrationAccount enqueueSnackbar={enqueueSnackbar} />,
  };

  if (!_isNull(registrationStep)) {
    switch (registrationStep) {
      case 0:
        attributes.stepComponent = (
          <VerifyRegistration enqueueSnackbar={enqueueSnackbar} setIsLoading={setIsLoading} />
        );
        break;
      case 1:
        attributes.stepComponent = (
          <DoctorGeneralProfile enqueueSnackbar={enqueueSnackbar} setIsLoading={setIsLoading} />
        );
        break;
      case 2:
        attributes.stepComponent = (
          <DoctorWorkExperience enqueueSnackbar={enqueueSnackbar} setIsLoading={setIsLoading} />
        );
        break;
      case 3:
        attributes.stepComponent = (
          <DoctorPassportUpload enqueueSnackbar={enqueueSnackbar} setIsLoading={setIsLoading} />
        );
        break;
      default:
    }
  }
  return attributes;
};

const SignUpSteppers = ({ classes, enqueueSnackbar }) => {
  const { registration_step: registrationStep } = window.gon;
  const [isLoading, setIsLoading] = useState(false);
  const { stepComponent } = stepAttributes(registrationStep, enqueueSnackbar, setIsLoading);
  const { t } = useTranslation();
  const width = useResize();
  const isSignUp = window.location.pathname === '/doctor/sign-up';

  return (
    <MuiThemeProvider theme={DoctorPlatformTheme}>
      <div className={classes.main}>
        <Container className={classes.container}>
          <Grid container alignItems="center" className={classes.main_content}>
            {isSignUp && (
              <Grid item xs={12} sm={12} className={classes.logo_container}>
                <img
                  alt="App Logo"
                  src={DOCTOR_PLATFORM_LOGO}
                  className={classes.logo}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={width <= 768 ? 12 : 6} className={`${classes.textContent} ${classes.sign_up_text}`}>
              {!isSignUp && (
                <img
                  alt="App Logo"
                  src={DOCTOR_PLATFORM_LOGO}
                  className={classes.logo}
                />
              )}
              <Typography variant="body2" className={`${lang === 'jp' && isSignUp ? classes.sign_up_text_bottom : ''}`}>
                {t('sign_up.intro_1')}
                {lang === 'jp' ? (
                  <>
                    <br />
                    医師資格証などにより医療資格が確認され次第、
                    <br />
                    登録完了となります。
                  </>
                ) : '' }
                <br />
                {lang === 'en' ? t('sign_up.intro_2') : ''}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={width <= 768 ? 12 : 6} className={classes.center}>
              {stepComponent}
            </Grid>
          </Grid>
        </Container>
        <Footer />
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </MuiThemeProvider>
  );
};

SignUpSteppers.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(withSnackbar(SignUpSteppers));
