import Slider from 'react-slick';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import userResize from '../../hooks/useResize';

const styles = () => ({
  container_slider_intro: {
    maxWidth: '100%',
    overflow: 'hidden',
    backgroundColor: '#C9EAF2',
    padding: '36px 0',
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  container: {
    '&> .slick-list': {
      maxWidth: '1348px',
      margin: '0 auto',
      overflow: 'hidden',
    },
    '&> .slick-list > .slick-track': {
      display: 'flex',
    },
  },
  wrapper: {
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img_container: {
    width: '220px',
    height: '96px',
    display: 'grid',
    placeItems: 'center',
    overflow: 'hidden',
    '& img': {
      objectFit: 'cover',
      maxWidth: '100%',
      maxHeight: '96px',
    },
  },
  'slick-dots': {
    marginTp: '40px',
  },
});

function SliderIntroduction(props) {
  const { classes } = props;
  const { initCompanyLogo } = window.gon;
  const width = userResize();
  const isPab = width > 734 && width <= 960;

  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    speed: 1000,
    infinite: true,
    slidesToShow: (isPab || initCompanyLogo.length <= 3) ? 1 : 3,
    slidesToScroll: 1,
  };
  if (!initCompanyLogo.length) {
    return <></>;
  }
  return (
    <div className={classes.container_slider_intro}>
      <Slider {...settings} className={classes.container}>
        {
          initCompanyLogo.map(item => (
            <div className={classes.wrapper} key={item.id}>
              <div className={classes.img_container}>
                <img src={item.imageUrl} alt={item.title} />
              </div>
            </div>
          ))}
      </Slider>
    </div>
  );
}

SliderIntroduction.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(SliderIntroduction);
