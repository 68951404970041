import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import BACKGROUND_IMG from '../../../assets/images/background.png';
import LOGO_DR from '../../../assets/images/logo.png';
import PEOPLE_ICON from '../../../assets/images/people_icon.png';
import HERO_IMG from '../../../assets/images/hero_img.png';

const styles = {
  wrapper: {
    backgroundImage: `url(${BACKGROUND_IMG})`,
    padding: '12px 60px',
    height: '100%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    paddingTop: '50px',
    '@media (max-width: 768px)': {
      padding: '12px 16px',
      paddingTop: '100px',
    },
  },
  main_header: {
    // maxWidth: "1128px",
    width: '100%',
    margin: '0 auto',
    padding: '24px 0',
    backgroundImage: `url(${BACKGROUND_IMG})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    zIndex: '100',
    height: '96px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    // "@media (min-width: 1232px)": {
    //   paddingLeft: "380px",
    //   paddingRight: "380px",
    // },
    // "@media (min-width: 768px) and (max-width: 1231px)": {
    //   paddingLeft: "52px",
    //   paddingRight: "52px",
    // },
  },
  header_wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    maxWidth: '1128px',
    width: '100%',
    zIndex: '100',
  },
  logo_container: {
    height: '40px',
    width: '154px',
    '&> img': {
      maxWidth: '100%',
      height: 'auto',
      objectFit: 'cover',
    },
  },
  gruop_btn_header_wrapper: {
    display: 'flex',
  },
  box_btn_header_login: {
    marginRight: '12px',
  },
  box_btn_header_register: {
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  btn_icon_img: {
    marginRight: '4px',
    marginTop: '-9px',
    height: '20px',
    width: '20px',
    '& > img': {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
  },
  btn_header_login: {
    height: '48px',
    width: '107px',
    fontWeight: '500',
    color: '#466888',
  },
  btn_header_register: {
    height: '48px',
    width: '124px',
    borderRadius: '4px',
    fontWeight: '500',
    backgroundColor: '#FFFFFF',
    color: '#2C62D6',
    '&:hover': {
      opacity: '0.8',
      backgroundColor: '#FFFFFF',
    },
  },
  container: {
    maxWidth: '1128px',
    width: '100%',
    margin: '0 auto',
    marginBottom: '96px',
    '@media (max-width: 768px)': {
      marginBottom: '40px',
    },
  },
  content: {
    display: 'flex',
    marginTop: '60px',
    '@media (max-width: 768px)': {
      marginTop: '12px',
    },
  },
  content_left: {
    flex: 1,
    marginTop: '90px',
    paddingRight: '10px',
    '@media (max-width: 960px)': {
      marginTop: '22px',
    },
  },
  main_heading: {
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '52px',
    letterSpacing: '0em',
    textAlign: 'left',
    '@media (max-width: 1200px)': {
      fontSize: '36px',
      lineHeight: '46px',
    },

    '@media (max-width: 960px)': {
      fontSize: '28px',
      lineHeight: '32px',
    },
  },
  name_brand: {
    fontWeight: '700',
    color: '#2C62D6',
  },
  name_brand_tittle: {
    fontWeight: '700',
    color: '#152953',
    fontSize: '24px',
    whiteSpace: 'pre-wrap',
    '@media (max-width: 960px)': {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: '600',
    },
  },
  name_brand_sub_tittle: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#466888',
    marginTop: '28px',
    whiteSpace: 'pre-wrap',
    '@media (max-width: 1200px)': {
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '-0.1px',
    },
    '@media (max-width: 960px)': {
      fontSize: '14px',
      lineHeight: '20px',
      marginTop: '18px',
      '& > br': {
        display: 'none',
      },
    },
  },
  gruop_btn_hero_wrapper: {
    marginTop: '46px',
  },
  main_btn_hero: {
    display: 'flex',
    '@media (min-width: 735px)': {
      display: 'none',
    },
  },
  btn_item: {
    height: '50px',
    width: '124px',
    borderRadius: '4px',
    fontWeight: '500',
  },
  box_btn_item: {
    marginRight: '24px',
    '@media (max-width: 768px)': {
      marginRight: 0,
      width: '100%',
    },
  },
  box_btn_white: {
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  btn_item_white: {
    backgroundColor: '#FFFFFF',
    color: '#2C62D6',
    '&:hover': {
      opacity: '0.8',
      backgroundColor: '#FFFFFF',
    },
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  btn_item_blue: {
    backgroundColor: '#2C62D6',
    color: '#fff',
    '&:hover': {
      opacity: '0.8',
      backgroundColor: '#2C62D6',
    },
    '@media (max-width: 768px)': {
      marginRight: 0,
      width: '100%',
    },
  },
  contaner_img_banner: {
    flex: 1,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '&> img': {
      maxWidth: '100%',
      height: 'auto',
      objectFit: 'cover',
      borderRadius: '8px',
    },
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  link: {
    fontWeight: '500',
    color: '#466888',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  link_black: {
    color: '#fff',
  },
};

function SectionContact({ classes }) {
  const { isLogin } = window.gon;
  const { t } = useTranslation();
  const { is_active: isActive, is_sign_in: isSignIn } = isLogin;

  const renderBtnHeader = () => {
    if (isActive) {
      return (
        <div className={classes.box_btn_item}>
          <Button className={`${classes.btn_item} ${classes.btn_item_blue}`}>
            <a
              className={`${classes.link} ${classes.link_black}`}
              href="/landings"
            >
              Dashboard
            </a>
          </Button>
        </div>
      );
    }
    if (!isActive) {
      return (
        <div className={classes.box_btn_item}>
          <Button className={`${classes.btn_item} ${classes.btn_item_blue}`}>
            <a
              className={`${classes.link} ${classes.link_black}`}
              href="/landings"
            >
              {t('landing_login')}
            </a>
          </Button>
        </div>
      );
    }
    return <></>;
  };
  return (
    <div>
      <div className={classes.main_header}>
        <div className={classes.header_wrapper}>
          <div className={classes.logo_container}>
            <a className={classes.link} href="/">
              <img src={LOGO_DR} alt="LOGO_DR" />
            </a>
          </div>
          <div className={classes.gruop_btn_header_wrapper}>
            {isSignIn ? (
              renderBtnHeader()
            ) : (
              <>
                <div className={classes.box_btn_header_login}>
                  <Button className={classes.btn_header_login} color="primary">
                      <a className={classes.link} href="/doctor/sign-in">
                          <div className={classes.btn_icon_img}>
                              <img src={PEOPLE_ICON} alt="PEOPLE_ICON" />
                            </div>
                          {t('landing_login')}
                        </a>
                    </Button>
                </div>
                <div className={classes.box_btn_header_register}>
                  <Button className={classes.btn_header_register}>
                      <a className={`${classes.link}`} href="/doctor/sign-up">
                          {t('landing_register')}
                        </a>
                    </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div className={classes.content}>
            <div className={classes.content_left}>
              <h1 className={classes.main_heading}>
                <span className={classes.name_brand}>Contact us</span>
                <br />
              </h1>
              <p className={classes.name_brand_sub_tittle}>
                Send an email to us by clicking the link below.
              </p>
              <a href="mailto:?subject=Dr.Benriプラットフォームへのご招待&body=簡単に素早く医療情報を検索し、%0D%0A他の医師と互いの見解を共有しながら、%0D%0Aネットワーク構築ができる医師を対象とした%0D%0AプラットフォームDr.Benriへご招待させていただきたく存じます。%0D%0A下記のリンクよりご参照ください。%0D%0A%0D%0Ahttps://drbenri.com">
                Email us!
              </a>
              {/* <p className={classes.name_brand_sub_tittle}>Dr.Benriは、ジャパン・ヘルスケア・ネットワークが所有し管理しています。</p> */}
              {/* <p className={classes.name_brand_sub_tittle}>ジャパン・ヘルスケア・ネットワーク株式会社</p> */}
              {/* <p className={classes.name_brand_sub_tittle}>〒108-0073 東京都港区三田1丁目3-40 天翔オフィス麻布十番8階</p> */}
            </div>
            <div className={classes.contaner_img_banner}>
              <p />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SectionContact.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(SectionContact);
