import React from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import DoctorPlatformTheme from '../components/common/DoctorPlatformTheme';
import { DOCTOR_PLATFORM_LOGO } from '../shared/Constants';
import Footer from '../components/common/Footer';

const styles = theme => ({
  main: {
    height: 'calc(100vh - 42px)',
    width: '100%',
    background: 'linear-gradient(0deg, rgba(245, 250, 255, 0.30) -3.22%, #EDF6FF 78%)',
    '@media (max-width: 768px)': {
      height: '100%',
    },
  },
  container: {
    display: 'flex',
    height: '100%',
    paddingBottom: theme.spacing(6),
  },
  logo: { width: '50%' },
  mainGrid: {
    textAlign: 'center',
  },
  root: {
    minWidth: 275,
    maxWidth: 500,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    margin: 'auto',
    marginTop: theme.spacing(4),
  },
  des: { marginTop: theme.spacing(2) },
});


const Pending = ({ classes }) => {
  const { t } = useTranslation();
  return (
    <MuiThemeProvider theme={DoctorPlatformTheme}>
      <div className={classes.main}>
        <Container className={classes.container}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12} md={6} className={classes.mainGrid}>
              <img
                alt="App Logo"
                src={DOCTOR_PLATFORM_LOGO}
                className={classes.logo}
              />
              <Card className={classes.root}>
                <CardContent>
                  <Typography component="h2" variant="h5">
                    {t('Thank you for registering')}
                  </Typography>
                  <Typography className={classes.des} variant="body2" style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>
                    {t('Notification_confirm_register')}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </div>
    </MuiThemeProvider>
  );
};

Pending.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(Pending);
