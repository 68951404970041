import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import NEW1 from '../../../assets/images/new1.png';
import NEW2 from '../../../assets/images/new2.png';
import NEW3 from '../../../assets/images/new3.png';

const styles = {
  wrapper: {
    marginTop: '88px',
    maxWidth: '903px',
    backgroundColor: '#FFFFFF',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '88px',
    '@media (max-width: 768px)': {
      padding: '16px',
      gap: '32px',
      marginTop: '32px',
    },
  },
  newsDescriptionContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '66px',
    whiteSpace: 'pre-line',
    '@media (max-width: 768px)': {
      flexWrap: 'wrap',
      gap: '8px',
      fontSize: '14px',
    },
  },
  titleNew: {
    '@media (max-width: 768px)': {
      fontSize: '24px!important',
    },
  },
  titleNoDes: {
    color: '#3E6296',
    textAlign: 'center',
    fontFamily: 'Hiragino Sans, sans-serif',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '36px',
    letterSpacing: '0.01px',
    whiteSpace: 'pre-line',

    marginBottom: '40px',
    '@media (max-width: 768px)': {
      fontSize: '24px',
      marginBottom: '16px',
    },
  },
  imageNoDes: {
    width: '100%',
  },
  content: {
    whiteSpace: 'pre-line',
  },
  image: {
    maxWidth: '400px',
    '@media (max-width: 768px)': {
      maxWidth: '100%',
    },
  },
  detailContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
  },
  switchPosition: {
    order: 2,
  },
  title: {
    color: '#3E6296',
    fontFamily: 'Hiragino Sans, sans-serif',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '36px',
    letterSpacing: '0.01px',
  },
  line: {
    width: '88px',
    height: '6px',
    borderRadius: '100px',
    background: 'var(--secondary-main, #F2817C)',
  },
};

function SectionArticle({ classes, data }) {
  const { section_item: listArticle } = data;
  const imageData= [NEW1, NEW2, NEW3];
  return (
    <div className={classes.wrapper}>
      {listArticle.map((item, index) => (
        item.content
          ? (
            <div className={classes.newsDescriptionContainer} key={item.id}>
              <div className={`${(index % 2 === 0) ? classes.switchPosition : ''} ${classes.detailContainer}`}>
                <div className={`${classes.title} ${classes.titleNew}`}>{item.title}</div>
                <div className={classes.line} />
                <div className={classes.content}>{item.content}</div>
              </div>
              <img
                className={classes.image}
                src={item?.imageUrl}
                onError={(e) => { e.target.src = imageData[index]; }}
                alt="NEW"
              />
            </div>
          ) : (
            <div key={item.id}>
              <div className={classes.titleNoDes}>{item.title}</div>
              <img
                className={classes.imageNoDes}
                src={item?.imageUrl}
                onError={(e) => { e.target.src = imageData[index]; }}
                alt="NEW"
              />
            </div>
          )
      ))}
    </div>
  );
}

SectionArticle.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(SectionArticle);
