import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = () => ({
  wrapper: {
    overflow: 'hidden',
    backgroundColor: 'rgba(241, 252, 254, 0.9)',
    padding: '36px 16px',

  },
  list_img: {
    display: 'flex',
    flexDirection: 'column',
  },
  item_img: {
    marginBottom: '24px',
  },
  img_container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& >img': {
      maxWidth: '100%',
      height: 'auto',
      objectFit: 'cover',
      '@media (max-width: 1200px)': {
        paddingLeft: '12px',
        width: 'auto',
        maxWidth: '50%',
        maxHeight: '96px',
        paddingRight: '12px',
      },
    },
  },
});

function ListImageCompany(props) {
  const { classes } = props;
  const { initCompanyLogo } = window.gon;

  return (
    <div className={classes.wrapper}>
      <div className={classes.list_img}>
        {
          initCompanyLogo.map(itemImg => (
            <div className={classes.item_img} key={itemImg.id}>
              <div className={classes.img_container}>
                <img src={itemImg.imageUrl} alt={itemImg.title} />
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}

ListImageCompany.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(ListImageCompany);
