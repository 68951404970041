import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import _isUndefined from 'lodash/isUndefined';
import SectionWelcome from './SectionWelcome';
import SliderIntroduction from './SliderIntroduction';
import SectionArticle from './SectionArticle';
import SectionGuide from './SectionGuide';
import ListImageCompany from './ListImageCompany';
import userResize from '../../hooks/useResize';

const styles = {
  wrapper: {
    position: 'relative',
  },
};

function Introduction({ classes }) {
  const width = userResize();
  const isMobile = width <= 736;
  const arr = window.gon.initLandingIntroduction;
  const dataSectionWelcome = arr.find(sec => sec.name === 'welcome');
  const dataSectionArticle = arr.find(sec => sec.name === 'news');
  const dataSectionGuide = arr.find(sec => sec.name === 'started');
  return (
    <div className={classes.wrapper}>
      {!_isUndefined(dataSectionWelcome) && <SectionWelcome data={dataSectionWelcome} />}
      {/*<SliderIntroduction />*/}
      {/* ipad ---> Pc */}
      {!isMobile && !_isUndefined(dataSectionArticle) && (
        <SectionArticle data={dataSectionArticle} />
      )}
      {!isMobile && !_isUndefined(dataSectionGuide)
      && (
      <SectionGuide
        data={dataSectionGuide}
      />
      )}
      {isMobile
      && !_isUndefined(dataSectionArticle) && (
      <SectionArticle
        data={dataSectionArticle}
      />
      )}
      {isMobile && !_isUndefined(dataSectionGuide) && (
      <SectionGuide
        data={dataSectionGuide}
      />
      )}
      {/*{isMobile && <ListImageCompany />}*/}
    </div>
  );
}

Introduction.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(Introduction);
