import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import LOGO_DR from '../../../assets/images/logo.png';

const styles = {
  wrapper: {
    marginTop: '88px',
    display: 'flex',
    paddingTop: '56px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(0deg, rgba(245, 250, 255, 0.30) -3.22%, #EDF6FF 78%)',
    gap: '56px',
    '@media (max-width: 768px)': {
      padding: '32px 16px',
      marginTop: '32px',
      gap: '32px',
    },
  },
  heading_content: {
    color: '#3E6296',
    textAlign: 'center',
    fontFamily: 'Hiragino Sans, sans-serif',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '36px',
    letterSpacing: '0.01px',
  },
  guide_content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '906px',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  step_content: {
    position: 'relative',
    display: 'flex',
    width: '906px',
    justifyContent: 'space-around',
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      borderTop: '2px dashed rgba(21, 80, 209, 0.2)',
      top: '24px',
      left: 0,
      right: 0,
      width: '70%',
      transform: 'translate(20%, 0%)',
      zIndex: 1,
    },
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      '&::after': {
        top: '170px',
        left: 'calc(-25% - 12px)',
        width: '70%',
        transform: 'rotate(90deg)',
      },
    },
  },
  step_item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '290px',
    '@media (max-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      marginBottom: '12px',
      width: '100%',
    },
    '@media (max-width: 960px)': {
      marginBottom: '0',
    },
  },
  point_circle: {
    width: '48px',
    height: '48px',
    background: 'var(--secondary-03, #FFC5C2)',
    boxShadow: '0px 2px 4px 0px rgba(62, 98, 150, 0.25)',
    borderRadius: '50%',
    display: 'grid',
    placeItems: 'center',
    fontSize: '24px',
    fontWeight: '600',
    textAlign: 'center',
    color: 'var(--primary-main, #3E6296)',
    zIndex: 10,
    '@media (max-width: 1200px)': {
      width: '48px',
      height: '48px',
      fontSize: '18px',
    },
  },
  step_text_content: {
    marginTop: '24px',
    '@media (max-width: 768px)': {
      marginLeft: '12px',
      marginTop: 0,
    },
    '@media (max-width: 960px)': {
      marginBottom: '24px',
    },
  },
  title: {
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '24px',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    '@media (max-width: 768px)': {
      textAlign: 'left',
      fontSize: '18px',
      lineHeight: '21px',
    },
  },
  desc: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '24px',
    textAlign: 'center',
    marginTop: '8px',
    width: '290px',
    whiteSpace: 'pre-wrap',
    '@media (max-width: 768px)': {
      textAlign: 'left',
      fontSize: '16px',
      lineHeight: '24px',
      paddingRight: '8px',
    },
    '@media (max-width: 360px)': {
      wordBreak: 'break-word',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 3,
      display: '-webkit-box',
      fontSize: '14px',
      paddingRight: '44px',
    },
  },
  footerContainer: {
    display: 'flex',
    width: '100%',
    maxWidth: '1440px',
    height: '144px',
    padding: '0px 64px',
    justifyContent: 'space-between',
    '@media (max-width: 768px)': {
      height: '30px',
      maxWidth: 'unset',
      padding: '0px 8px',
    },
  },
  logo_container: {
    height: '44px',
    width: '100px',
    '&> img': {
      maxWidth: '100%',
      height: 'auto',
      objectFit: 'cover',
    },
    '@media (max-width: 768px)': {
      marginBottom: '24px',
    },
  },
  group_links: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '16px',
    '@media (max-width: 768px)': {
      gap: '8px',
    },
  },
  link_item: {
    color: 'var(--primary-main, #3E6296)',
    fontFamily: 'Hiragino Sans, sans-serif',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '24px',
    padding: '8px 16px',
    '@media (max-width: 768px)': {
      padding: '8px 0',
    },
  },
};


function SectionGuide({ classes, data }) {
  const { section_item: dataSectionGuide, title } = data;

  return (
    <div className={classes.wrapper}>
      <h2 className={classes.heading_content}>
        {title}
      </h2>
      <div className={classes.guide_content}>
        <div className={classes.step_content}>
          {
            dataSectionGuide.map((item, index) => (
              <div className={classes.step_item} key={item.id}>
                <div className={classes.point_circle}>
                  <span>{item?.numberStep || (index + 1)}</span>
                </div>
                <div className={classes.step_text_content}>
                  <h3 className={classes.title}>{item.title}</h3>
                  <p className={classes.desc}>
                    {index !== 2 && item.content}
                    {index === 2
                          && (
                          <a href="mailto:?subject=Dr.Benriプラットフォームへのご招待&body=簡単に素早く医療情報を検索し、%0D%0A他の医師と互いの見解を共有しながら、%0D%0Aネットワーク構築ができる医師を対象とした%0D%0AプラットフォームDr.Benriへご招待させていただきたく存じます。%0D%0A下記のリンクよりご参照ください。%0D%0A%0D%0Ahttps://drbenri.com">
                            {item.content}
                          </a>
                          )}
                  </p>
                </div>
              </div>
            ))
          }
        </div>
      </div>

      <div className={classes.footerContainer}>
        <div className={classes.logo_container}>
          <img src={LOGO_DR} alt="LOGO_DR" />
        </div>
        <div className={classes.group_links}>
          <a href="/about" className={classes.link_item}>Dr.Benriとは</a>
          <a href="mailto:contact@drbenri.com" className={classes.link_item}>お問い合わせ</a>
        </div>
      </div>
    </div>
  );
}

SectionGuide.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(SectionGuide);
