import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import withStyles from '@material-ui/core/styles/withStyles';
import Parser from 'html-react-parser';
import SubmitButton from '../common/SubmitButton';
import linkWithLang from '../../helpers/LinkWithLanguage';

const styles = theme => ({
  root: {
    minWidth: 275,
    maxWidth: 500,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textAlign: 'left',
  },
  des: { marginTop: theme.spacing(2) },
  callAction: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
  },
  signUpLink: {
    marginLeft: theme.spacing(1),
    flex: '1',
  },
  form: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  inputLabel: { fontSize: '1rem' },
  rememberMe: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media (max-width:500px)': {
      display: 'block',
    },
  },
  lab_log_in: {
    margin: '0',
  },
  text: {
    '@media (max-width:800px)': {
      fontSize: '13px',
    },
    '@media (max-width:500px)': {
      fontSize: '14px',
      fontWeight: 'normal',
    },
  },
  label_gruop: {
    '@media (max-width:500px)': {
      width: '100%',
    },
  },
});

class SignInForm extends Component {
  state = {
    showPassword: false,
    rememberMe: false,
    formAttrs: {},
    childNodes: '',
    email: '',
    password: '',
  };

  componentDidMount = () => {
    const originalForm = document.getElementById('doctor-sign-in-form');
    if (!originalForm) { return; }
    const formAttrs = ['action', 'acceptCharset', 'method'].reduce((obj, val) => {
      const transformObj = obj;
      transformObj[val] = originalForm[val];
      return transformObj;
    }, {});
    const childNodes = originalForm.innerHTML;
    this.setState({
      formAttrs,
      childNodes,
    });
  }

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  handleClickRememberMe = (e) => {
    this.setState({ rememberMe: e.target.checked });
  };

  render() {
    const { classes, t } = this.props;
    const {
      showPassword, formAttrs, childNodes, rememberMe, password,
    } = this.state;

    const email = this.props.email || this.state.email;
    const isValid = () => {
      let valid = false;

      if (!_isEmpty(email) && !_isEmpty(password)) {
        valid = true;
      }
      return valid;
    };

    return (
      <Card className={classes.root}>
        <CardContent>
          <Typography component="h2" variant="h5">
            {t('Sign in')}
          </Typography>
        </CardContent>
        <div className={classes.form}>
          <form {...formAttrs}>
            {Parser(childNodes)}
            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="email">{t('Email Address')}</InputLabel>
              <Input
                id="doctor_email"
                name="doctor[email]"
                autoComplete="email"
                value={email}
                autoFocus
                onChange={e => this.setState({ email: e.target.value })}
              />
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="password">{t('Password')}</InputLabel>
              <Input
                name="doctor[password]"
                type={showPassword ? 'text' : 'password'}
                id="doctor_password"
                autoComplete="current-password"
                autoFocus={this.props.email}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )}
                onChange={e => this.setState({ password: e.target.value })}
              />
            </FormControl>
            <div className={classes.rememberMe}>
              <FormControl
                className={classes.label_gruop}
              >
                <FormControlLabel
                  control={(
                    <Switch
                      onChange={e => this.handleClickRememberMe(e)}
                      name="remember_me"
                      color="primary"
                      inputProps={
                        { 'aria-label': 'secondary checkbox', name: 'doctor[remember_me]', value: rememberMe }
                      }
                    />
                  )}
                  className={classes.lab_log_in}
                  label={<Typography className={classes.text} variant="body2">{t('Stay logged in')}</Typography>}
                />
              </FormControl>
              <Link className={`${classes.text}`} href={linkWithLang('/doctor/password/new')}>{t('Forgot password')}</Link>
            </div>
            <div className={classes.callAction}>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Link href={linkWithLang('/doctor/sign-up')} className={classes.signUpLink}>{t('Create one')}</Link>
              </div>
              <SubmitButton isValid={isValid()} text={t('Sign in')} />
            </div>
          </form>
        </div>
      </Card>
    );
  }
}

SignInForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
};

export default withStyles(styles)(withTranslation()(SignInForm));
