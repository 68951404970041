import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import SnackbarContainer from '../components/common/SnackbarContainer';
import SignUpSteppers from '../containers/SignUpSteppers';
import SignInProcess from '../containers/SignInProcess';
import Pending from '../containers/Pending';
import Introduction from '../containers/Introduction';
import About from '../containers/AboutContainer';
import ContactUs from '../containers/ContactUsContainer';
import OneTimeRegistered from '../components/contactus/OneTimeRegistered';

export default (
  <Router>
    <SnackbarContainer>
      <Switch>
        <Route path="/" exact component={Introduction} />
        <Route path="/about" exact component={About} />
        <Route path="/contact-us" exact component={ContactUs} />
        <Route path="/one-time-registered" exact component={OneTimeRegistered} />
        <Route path="/pending" exact component={Pending} />
        <Route path="/doctor" exact component={SignUpSteppers} />
        <Route path="/doctor/sign-up" exact component={SignUpSteppers} />
        <Route path="/doctor/confirmation" exact component={SignUpSteppers} />
        <Route path="/doctor/sign-in" exact component={SignInProcess} />
        <Route path="/doctor/password/new" exact component={SignInProcess} />
        <Route path="/doctor/password/edit" exact component={SignInProcess} />
        <Route path="/doctor/verification" exact component={SignInProcess} />
      </Switch>
    </SnackbarContainer>
  </Router>
);
