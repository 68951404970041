import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import _isArray from 'lodash/isArray';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Link from '@material-ui/core/Link';
import LOGO_DR from '../../../assets/images/logo.png';
import PEOPLE_ICON from '../../../assets/images/people_icon.png';
import HERO_IMG from '../../../assets/images/hero_img.png';
import CANCER1 from '../../../assets/images/cancer1.png';
import CANCER2 from '../../../assets/images/cancer2.png';
import AxiosHeaders from '../../helpers/AxiosHeaders';
import PushSnackbarMessage from '../../helpers/PushSnackbarMessage';

const styles = {
  wrapper: {
    background: 'linear-gradient(97deg, rgba(245, 250, 255, 0.30) 2.77%, #EDF6FF 100.17%)',
    padding: '16px 64px',
    '@media (max-width: 768px)': {
      padding: '16px',
      height: 'auto',
    },
    height: '773px',
  },
  main_header: {
    width: '100%',
    margin: '0 auto',
    padding: '16px 64px',
    background: 'linear-gradient(97deg, rgba(245, 250, 255, 0.30) 2.77%, #EDF6FF 100.17%)',
    zIndex: '100',
    height: '72px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
  },
  header_wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    zIndex: '100',
  },
  leftHeader: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    width: 'max-content',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'unset',
    },
  },
  logo_container: {
    height: '40px',
    width: '154px',
    '&> img': {
      maxWidth: '100%',
      height: 'auto',
      objectFit: 'cover',
    },
  },
  betaVersion: {
    border: '1px solid #3E6296',
    padding: '0 4px',
    borderRadius: '8px',
    '@media (max-width: 768px)': {
      width: 'fit-content',
      marginLeft: '4px',
    },
  },
  group_btn_header_wrapper: {
    display: 'flex',
    gap: '12px',
  },
  box_btn_header_register: {
    '@media (max-width: 768px)': {
      position: 'absolute',
      top: '64px',
    },
  },
  btn_icon_img: {
    marginRight: '4px',
    marginTop: '-9px',
    height: '20px',
    width: '20px',
    '& > img': {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
  },
  btn_header_login: {
    height: '40px',
    width: '120px',
    padding: '8px 16px',
    borderRadius: '8px',
    border: '1px solid #3E6296',
  },
  btn_header_register: {
    height: '40px',
    width: '120px',
    padding: '8px 16px',
    borderRadius: '8px',
    backgroundColor: '#3E6296',
    '&:hover': {
      opacity: '0.9',
      backgroundColor: '#3E6296',
    },
  },
  btn_auto: {
    width: '100%',
  },
  container: {
    margin: '0 auto',
    maxWidth: '1440px',
  },
  title: {
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: '64px',
    marginBottom: '51px',
    gap: '16px',
    '@media (max-width: 768px)': {
      marginBottom: '24px',
    },
  },
  content: {
    display: 'flex',
    width: '1142px',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 'auto',
    '@media (max-width: 768px)': {
      width: '100%',
      flexDirection: 'column',
    },
  },
  content_left: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
    width: '438px',
    height: '315px',
    '@media (max-width: 768px)': {
      width: '100%',
      height: 'auto',
      alignItems: 'flex-start',
    },
  },
  main_heading: {
    fontSize: '44px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '52px',
    letterSpacing: '0.01px',
    textAlign: 'center',
    '@media (max-width: 768px)': {
      fontSize: '38px',
    },
  },
  name_brand: {
    fontWeight: '600',
    color: '#3E6296',
  },
  name_brand_tittle: {
    fontWeight: '600',
    color: '#4D71A6',
    fontSize: '20px',
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    '@media (max-width: 768px)': {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: '600',
    },
  },
  name_brand_sub_tittle: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'center',
    color: '#466888',
    marginTop: '28px',
    whiteSpace: 'pre-wrap',
    '@media (max-width: 768px)': {
      fontSize: '14px',
    },
  },
  btn_item: {
    height: '50px',
    width: '124px',
    borderRadius: '4px',
    fontWeight: '500',
  },
  box_btn_item: {
    marginRight: '24px',
    '@media (max-width: 768px)': {
      marginRight: 0,
      width: '100%',
    },
  },
  box_btn_white: {
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  btn_item_white: {
    backgroundColor: '#FFFFFF',
    color: '#2C62D6',
    '&:hover': {
      opacity: '0.8',
      backgroundColor: '#FFFFFF',
    },
    '@media (max-width: 768px)': {
      fontSize: '12px',
    },
  },
  btn_item_blue: {
    backgroundColor: '#2C62D6',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#2C62D6',
    },
  },
  container_img_banner_mobile: {
    display: 'none',
    width: '100%',
    '@media (max-width: 768px)': {
      display: 'block',
      marginBottom: '16px',
    },
    '&> img': {
      objectFit: 'cover',
    },
  },
  container_img_banner: {
    flex: 1,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '&> img': {
      objectFit: 'cover',
    },
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  link: {
    fontWeight: '600',
    color: '#3E6296',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  link_primary: {
    color: '#FBFBFB',
  },
  link_black: {
    color: '#fff',
  },
  cancerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '100%',
  },
  cancerImageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  cancerText: {
    color: '#313033',
  },
  cancerImage: {
    display: 'flex',
    maxWidth: '208px',
    minWidth: '180px',
    padding: '8px 12px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    borderRadius: '8px',
    border: '1px solid var(--secondary-main, #F2817C)',
    background: 'var(--primary-02, #F2F6FF)',
    '@media (max-width: 768px)': {
      maxWidth: 'unset',
      minWidth: 'unset',
      width: '42%',
    },
  },
  registerContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '20px',
  },
  registerFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px',
    width: '100%',
  },
  registerInputContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    gap: '8px',
    alignSelf: 'stretch',
    '@media (max-width: 768px)': {
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
  },
  formControl: {
    display: 'flex',
    height: '48px',
    padding: '10px',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '4px',
    border: '1px solid var(--primary-15, #4D71A6)',
    background: '#FFF',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  formControlSmall: {
    width: '104px',
    '@media (max-width: 768px)': {
      width: '42%',
    },
  },
  registerDescriptionText: {
    color: '#525253',
    '@media (max-width: 768px)': {
      fontSize: '14px',
    },
  },
  required: {
    color: '#F2817C',
  },
  error: {
    border: '1px solid var(--primary-15, #F2817C)',
  },
  dialog: {
    width: '550px',
    height: '430px',
    padding: '16px',
    '@media (max-width: 768px)': {
      height: '370px',
      width: '100%',
    },
  },
  dialogStep2: {
    height: '500px',
    '@media (max-width: 768px)': {
      height: '435px',
      width: '102%',
      fontSize: '12px!important',
    },
  },
  links: {
    display: 'flex',
    gap: '8px',
  },
  confirmPopup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    color: '#313033',
    '@media (max-width: 768px)': {
      fontSize: '12px',
    },
  },
  userInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    gap: '16px',
    wordBreak: 'break-word',
    margin: '16px 0',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      gap: '0',
    },
  },
  messageTitle: {
    color: '#313033',
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '16px',
    '@media (max-width: 768px)': {
      fontSize: '16px',
    },
  },
  messageContent: {
    fontSize: '16px',
    lineHeight: '1.5',
    color: '#313033',
    '@media (max-width: 768px)': {
      fontSize: '12px',
    },
  },
  dBlock: {
    display: 'block',
  },
  cancelBtn: {
    fontSize: '14px',
  },
  agreeBtn: {
  },
};

function SectionWelcome({ classes, data }) {
  const isMobile = window.innerWidth <= 768;
  const { t } = useTranslation();
  const { title, sub_title: subTitle, section_item: dataItem } = data;
  const { content, imageUrl } = dataItem[0];
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [touchedLastName, setTouchedLastName] = useState(false);
  const [touchedFirstName, setTouchedFirstName] = useState(false);
  const [touchedEmail, setTouchedEmail] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [registerSuccess, setRegisterSuccess] = useState(false);

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleInputBlur = () => {
    setTouchedEmail(true);
  };

  const handleInputLastNameBlur = () => {
    setTouchedLastName(true);
  };

  const handleInputFirstNameBlur = () => {
    setTouchedFirstName(true);
  };

  const hasErrorLastName = (e) => {
    if (!touchedLastName) {
      return false;
    }
    if (touchedLastName && e.trim() === '') {
      return true;
    }
    return !lastName.length;
  };

  const hasErrorFirstName = (e) => {
    if (!touchedFirstName) {
      return false;
    }
    if (touchedFirstName && e.trim() === '') {
      return true;
    }
    return !lastName.length;
  };
  const hasError = (e) => {
    if (!touchedEmail) {
      return false;
    }
    if (touchedEmail && e.trim() === '') {
      return true;
    }
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return !emailPattern.test(e);
  };

  const handleSendRequestRegister = () => {
    if (registerSuccess) {
      setIsOpenDialog(false);
      setRegisterSuccess(false);
      return;
    }
    axios({
      url: `/auto_register/register?email=${email}&first_name=${firstName}&last_name=${lastName}`,
      method: 'get',
      headers: AxiosHeaders,
    }).then((res) => {
      if (res.status === 200) {
        setFirstName('');
        setLastName('');
        setEmail('');
        setTouchedLastName(false);
        setTouchedFirstName(false);
        setTouchedEmail(false);
        setRegisterSuccess(true);
      }
    }).catch(({ response: { data } }) => {
      if (_isArray(data.message)) {
        data.message.map(me => PushSnackbarMessage(enqueueSnackbar, me, 'success'));
      } else {
        PushSnackbarMessage(enqueueSnackbar, data.message, 'error');
      }
    });
  };

  const handleClickSubmit = () => {
    const errorLastName = !lastName || (lastName && hasErrorFirstName(lastName));
    if (errorLastName) {
      setTouchedLastName(true);
    }
    const errorFirstName = !firstName || (firstName && hasErrorLastName(firstName));
    if (errorFirstName) {
      setTouchedFirstName(true);
    }
    const errorEmail = !email || (email && hasError(email));
    if (errorEmail) {
      setTouchedEmail(true);
    }
    if (errorLastName || errorFirstName || errorEmail) {
      return;
    }
    setIsOpenDialog(true);
  };

  const renderDialogTermAndCondition = () => (
    <div>
      <Dialog
        open={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className={`${classes.dialog} ${registerSuccess ? classes.dialogStep2 : ''}`}>
          <DialogContentText id="alert-dialog-description">
            {
                  !registerSuccess
                    ? (
                      <div className={classes.confirmPopup}>
                        <div className={classes.logo_container}>
                          <img src={LOGO_DR} alt="LOGO_DR" />
                        </div>
                        <div className={classes.userInfo}>
                          {lastName && <div>{lastName}</div>}
                          {firstName && <div>{firstName}</div>}
                          {email && <div>{email}</div>}
                        </div>
                        {
                              !isMobile ? (
                                <>
                                  <p>
                                    <span className={classes.dBlock}>氏名および勤務先のメールアドレスが 正しいことをご確認ください。</span>
                                  </p>
                                  <p>
                                    <span className={classes.dBlock}>注：登録にお使いいただけるのは、病院のメールアドレスのみと</span>
                                    <span className={classes.dBlock}>なりますことをご了承ください。</span>
                                  </p>
                                  <p>
                                    <span className={classes.dBlock}>
                                      【確定】をクリックいたしますと、当社の
                                      <Link href="/Dr_Benri_Privacy_Policy.pdf" target="blank">{t('Privacy and Cookies') }</Link>
                                    </span>
                                    <span className={classes.dBlock}>
                                      および
                                      <Link href="/Dr_Benri_Terms_of_Use.pdf" target="blank">{t('Legal') }</Link>
                                      に同意いただいたこととなります。
                                    </span>
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p>
                                    <span className={classes.dBlock}>氏名および勤務先のメールアドレスが</span>
                                    <span className={classes.dBlock}>正しいことをご確認ください。</span>
                                  </p>
                                  <p>
                                    <span className={classes.dBlock}>注：登録にお使いいただけるのは</span>
                                    <span className={classes.dBlock}>病院のメールアドレスのみと</span>
                                    <span className={classes.dBlock}>なりますことをご了承ください。</span>
                                  </p>
                                  <p>
                                    <span className={classes.dBlock}>【確定】をクリックいたしますと、</span>
                                    <span className={classes.dBlock}>
                                      当社の
                                      <Link href="/Dr_Benri_Privacy_Policy.pdf" target="blank">{t('Privacy and Cookies') }</Link>
                                      および
                                    </span>
                                    <span className={classes.dBlock}>
                                      <Link href="/Dr_Benri_Terms_of_Use.pdf" target="blank">{t('Legal') }</Link>
                                      に同意いただいたことと
                                    </span>
                                    <span className={classes.dBlock}>なります。</span>
                                  </p>
                                </>
                              )
                            }
                      </div>
                    )
                    : (
                      <div className={classes.confirmPopup}>
                        <h3 className={classes.messageTitle}>Dr.Benri - 先生の登録依頼に関して</h3>
                        <div className={classes.messageContent}>
                          {!isMobile ? (
                            <>
                              <p>
                                <span className={classes.dBlock}>この度はDr.Benriへの登録に興味を持っていただき、</span>
                                <span className={classes.dBlock}>大変感謝しております。</span>
                              </p>
                              <p>
                                <span className={classes.dBlock}>Dr.Benriへの登録承認にはサイト管理者により、</span>
                                <span className={classes.dBlock}>先生のご依頼内容を確認させていただく必要がございます。</span>
                              </p>

                              <p>
                                <span className={classes.dBlock}>確認作業には数日かかる場合がございますこと、ご留意ください。</span>
                              </p>

                              <p>
                                <span className={classes.dBlock}>ご登録が承認されましたら、登録いただいたメールに仮パスワードを</span>
                                <span className={classes.dBlock}>送信させていただきます。</span>
                              </p>

                              <p>
                                <span className={classes.dBlock}>今しばらくお待ちください。</span>
                              </p>

                              <p>
                                <span className={classes.dBlock}>何卒よろしくお願い申し上げます。</span>
                              </p>
                              <p>
                                <span className={classes.dBlock}>Dr.Benri チーム</span>
                              </p>
                            </>
                          ) : (
                            <>
                              <p>
                                <span className={classes.dBlock}>この度はDr.Benriへの登録に興味を</span>
                                <span className={classes.dBlock}>持っていただき、大変感謝しております。</span>
                              </p>
                              <p>
                                <span className={classes.dBlock}>Dr.Benriへの登録承認にはサイト管理者により、</span>
                                <span className={classes.dBlock}>先生のご依頼内容を確認させていただく必要が</span>
                                <span className={classes.dBlock}>ございます。</span>
                              </p>

                              <p>
                                <span className={classes.dBlock}>確認作業には数日かかる場合が</span>
                                <span className={classes.dBlock}>ございますこと、ご留意ください。</span>
                              </p>

                              <p>
                                <span className={classes.dBlock}>ご登録が承認されましたら、登録いただいた</span>
                                <span className={classes.dBlock}>メールに仮パスワードを送信させていただきます。</span>
                              </p>

                              <p>
                                <span className={classes.dBlock}>今しばらくお待ちください。</span>
                              </p>

                              <p>
                                <span className={classes.dBlock}>何卒よろしくお願い申し上げます。</span>
                              </p>
                              <p>
                                <span className={classes.dBlock}>Dr.Benri チーム</span>
                              </p>
                            </>
                          )
                              }
                        </div>
                      </div>
                    )
                }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!registerSuccess && (
          <Button
            className={classes.btn_item_white}
            onClick={() => {
              setIsOpenDialog(false);
            }}
            color="primary"
          >
            {t('Cancel')}
          </Button>
          )}
          <Button className={classes.btn_item_blue} onClick={handleSendRequestRegister} color="primary" autoFocus>
            {registerSuccess ? '閉じる' : '確定'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  return (
    <div className={classes.wrapper}>
      {renderDialogTermAndCondition()}
      <div className={classes.header_wrapper}>
        <div className={classes.logo_container}>
          <div className={classes.leftHeader}>
            <a className={classes.link} href="/">
              <img src={LOGO_DR} alt="LOGO_DR" loading="lazy"/>
            </a>
            <div className={classes.betaVersion}>ベータ版</div>
          </div>
        </div>
        <div className={classes.group_btn_header_wrapper}>
          <Button className={classes.btn_header_login} color="primary">
            <a className={classes.link} href="/doctor/sign-in">
              <div className={classes.btn_icon_img}>
                <img src={PEOPLE_ICON} alt="PEOPLE_ICON" loading="lazy"/>
              </div>
              {t('landing_login')}
            </a>
          </Button>
          <div className={classes.box_btn_header_register}>
            <Button className={classes.btn_header_register}>
              <a className={`${classes.link} ${classes.link_primary}`} href="/doctor/sign-up">
                {t('landing_register')}
              </a>
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.container}>
        <div className={classes.title}>
          <h1 className={classes.main_heading}>
            <span className={classes.name_brand}>{title}</span>
          </h1>
          <h3 className={`${classes.name_brand} ${classes.name_brand_tittle}`}>
            {subTitle}
          </h3>
          <p className={classes.name_brand_sub_tittle}>{content}</p>
        </div>
        <div className={classes.content}>
          <div className={classes.container_img_banner_mobile}>
            <img
                src={imageUrl}
                width={'auto'}
                height="250"
                onError={(e) => { e.target.src = HERO_IMG; }}
                alt="HERO_IMG"
            />
          </div>
          <div className={classes.content_left}>
            <div className={classes.cancerContainer}>
              <div className={classes.cancerText}>
                現在は癌領域に焦点を当てております
              </div>
              <div className={classes.cancerImageContainer}>
                <div className={classes.cancerImage}>
                  <img src={CANCER1} alt="CANCER1" loading="lazy"/>
                  <div>前立腺がん</div>
                </div>
                <div className={classes.cancerImage}>
                  <img src={CANCER2} alt="CANCER2" loading="lazy"/>
                  <div>乳癌</div>
                </div>
              </div>
            </div>
            <div className={classes.registerContainer}>
              <div className={classes.registerFormContainer}>
                <div className={classes.registerInputContainer}>
                  <input
                    id="lastName"
                    type="text"
                    className={`${classes.formControl} ${classes.formControlSmall} ${hasErrorLastName(lastName) ? classes.error : ''}`}
                    placeholder="姓*"
                    onBlur={handleInputLastNameBlur}
                    value={lastName}
                    onChange={handleLastNameChange}
                  />
                  <input
                    id="firstName"
                    type="text"
                    className={`${classes.formControl} ${classes.formControlSmall} ${hasErrorFirstName(firstName) ? classes.error : ''}`}
                    placeholder="名*"
                    onBlur={handleInputFirstNameBlur}
                    value={firstName}
                    onChange={handleFirstNameChange}
                  />
                  <input
                    id="email"
                    type="text"
                    value={email}
                    onBlur={handleInputBlur}
                    onChange={handleEmailChange}
                    className={`${classes.formControl} ${hasError(email) ? classes.error : ''}`}
                    placeholder="勤務先のメール*"
                  />
                </div>
                <div className={classes.registerDescriptionText}>
                  <span className={classes.required}>*</span>
                  登録は勤務先のメールのみとさせていただきます
                </div>
              </div>
              <Button className={`${classes.btn_header_register} ${classes.btn_auto}`} onClick={handleClickSubmit}>
                <span className={`${classes.link} ${classes.link_primary}`}>
                  簡単登録
                </span>
              </Button>
            </div>
            <div className={classes.cancerText}>
              {!isMobile ? (
                <>(会員登録は現在病院勤務の医師のみを対象としております）</>
              ) : (
                <>
                  <span className={classes.dBlock}>(会員登録は現在病院勤務の医師のみを</span>
                  <span className={classes.dBlock}>対象としております）</span>
                </>
              )}
            </div>
          </div>
          <div className={classes.container_img_banner}>
            <img
              src={imageUrl}
              width="618"
              height="433"
              onError={(e) => { e.target.src = HERO_IMG; }}
              alt="HERO_IMG"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

SectionWelcome.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(SectionWelcome);
